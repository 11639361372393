export const SESION_TOKEN_JWT = "SESION_JWT_TOKEN"
export const SESION_TOKEN_REFRESCO_JWT = "SESION_JWT_REFRESH_TOKEN"
export const SESION_TIME_TOKEN_JWT = "SESION_JWT_TIME_TOKEN"
export const SESION_RECARGA_ID = "SESION_ID_RECARGA_SESION"
export const SESION_REDIRECT_SESION = "SESION_REDIRECT_SESION"
export const SESION_ADMIN_PERMITIDO = "SESION_ADMIN_PERMITIDO"

// URL
export const URL_API = "https://www.opos.reyesi.com.es"
export const HOST = `${window.location.protocol}//${window.location.host}`
export const URL_HOME = "/public/";
export const URL_ADMIN_HOME = "/public/admin/home"
export const URL_ADMIN_OPOS_SEARCH_DATE = "/public/admin/opos?fecha="
export const URL_ADMIN_OPOS_MODIFICAR = "/public/admin/modificar/opo?opoUuid="
export const URL_ADMIN_ADD_PASO_PROCESO_OPO = "/public/admin/addFaseProceso/opo?uuid="
export const URL_ADMIN_MODIFICAR_PASO_PROCESO_OPO = "/public/admin/modificarProceso/opo?uuid="
export const URL_ADMIN_VER_PROCESO_OPO = "/public/admin/verProceso/opo"
export const URL_ADMIN_CREAR_PROCESO_OPO = "/public/admin/crearProceso/opo?opoUuid="
export const URL_ADMIN_OPOS = "/public/admin/opos"
export const URL_ADMIN_CALLBACK = "/public/admin/callback"
export const URL_ADMIN_HOME_LOGIN = `${URL_ADMIN_HOME}/login`

// Datos oauth
export const OAUTH_CLIENT_ID = "opos-galicia-admin";
export const OAUTH_CLIENT_SECRET= "Y7gyjCp5dQRdiUY5VkDaBmC8udrpibL5"
export const OAUTH_RESPONSE_TYPE = "code"
export const OAUTH_GRANT_TYPE = "authorization_code"
export const OAUTH_GRANT_TYPE_REFRESH = "refresh_token"
export const OAUTH_REDIRECT_URL= `${HOST}${URL_ADMIN_CALLBACK}`

// Cadenas
export const CONSTANTE_ARCHIVO_PDF_BASE64 = "data:application/pdf;base64,"