import React, {useEffect, useState} from "react";
import {
    callApiAdministrarAddFaseProcesoOpo,
    callApiAdministrarEliminarOpo,
    callApiOposConsultaPorUuidOpoAdmin
} from "../../../core/restUtils";
import MenuAdministracion from "../../../component/menu/administrador/menu";
import Footer from "../../../component/footer/footer";
import {URL_ADMIN_VER_PROCESO_OPO} from "../../../core/constantes";
import {useNavigate} from "react-router-dom";

export default function AdministrarAddfaseProcesoAdmin() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [archivo, setArchivo] = useState(null);
    const [values, setValues] = React.useState({
        uuidOposicionDetalle: "",
        uuidOposicion: "",
        titulo: "",
        url: "",
        fechaBop: ""
    });

    useEffect(() => {
        setLoading(true)

        // recuperamos el querystring
        const querystring = window.location.search
        console.log(querystring)

        // usando el querystring, creamos un objeto del tipo URLSearchParams
        const params = new URLSearchParams(querystring)
        console.log(params)

        if (params != null && params.get("uuid") != null) {
            console.log("Buscado por uuid")
            asignarUuidProcesoAForm(params.get("uuid"));
        }

        setLoading(false)
    }, []);

    const asignarUuidProcesoAForm = async (uuid) => {
        // Asignar valores
        setValues({
            uuidOposicionDetalle: uuid
        });
    };

    const addFaseAProceso = async () => {
        try {
            setLoading(true);
            await callApiAdministrarAddFaseProcesoOpo(values.uuidOposicionDetalle, values.uuidOposicion, values.titulo, values.url, values.fechaBop, archivo);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    function handleChange(evt) {
        /*
          evt.target es el elemento que ejecuto el evento
          name identifica el input y value describe el valor actual
        */
        const { target } = evt;
        const { name, value } = target;

        /*
          Este snippet:
          1. Clona el estado actual
          2. Reemplaza solo el valor del
             input que ejecutó el evento
        */
        const newValues = {
            ...values,
            [name]: value,
        };

        // Sincroniza el estado de nuevo
        setValues(newValues);
    }

    // Manejar la selección de archivo
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        // Convertir el archivo a Base64
        const reader = new FileReader();
        reader.onload = () => {
            setArchivo(
                reader.result.split(",")[1] // Obtener solo el contenido Base64
            );
        };
        reader.readAsDataURL(selectedFile);

    };

    function handleSubmit(evt) {
        /*
          Previene el comportamiento default de los
          formularios el cual recarga el sitio
        */
        evt.preventDefault();

        addFaseAProceso();

        alert("Opo añadida correctamente")

        setLoading(true);

        navigate(URL_ADMIN_VER_PROCESO_OPO);

    }

    const renderFormularioAddProceso = () => {

        if (loading) return <div className="carga"><p><img key="carga" alt="carga" src="/public/loading.gif" height="100" width="100"/></p></div>;
        if (error) return <div className="carga"><p>Error: {error.message}</p></div>;

        return (
            <>
                <form onSubmit={handleSubmit}>
                    <h2>Crear proceso OPO</h2>
                    <div className="modificarOpo" key="modificarOpoId">

                        <label htmlFor="uuidOposicionDetalleId">UUID Proceso:</label>
                        <input type="text" name="uuidOposicionDetalle" id="uuidOposicionDetalleId" value={values.uuidOposicionDetalle}
                               onChange={handleChange}/>

                        <label htmlFor="uuidOposicionId">UUID Opo:</label>
                        <input type="text" name="uuidOposicion" id="uuidOposicionId"
                               onChange={handleChange}/>

                        <label htmlFor="tituloId">Titulo:</label>
                        <input type="text" name="titulo" id="tituloId" onChange={handleChange}/>

                        <label htmlFor="urlId">URL:</label>
                        <input type="text" name="url" id="urlId" onChange={handleChange}/>

                        <label htmlFor="archivoId">Archivo:</label>
                        <input name="archivo" id="archivoId" type="file" onChange={handleFileChange}/>

                        <label htmlFor="fechaBopId">Fecha BOP:</label>
                        <input type="date" name="fechaBop" id="fechaBopId" onChange={handleChange}/>

                        <button type="submit">Crear</button>
                    </div>
                </form>
            </>
        )
    };

    return (
        <>
            {<MenuAdministracion/>}
            <div className="content">
                <h1>Administrar Proceso Oposicion</h1>
                {renderFormularioAddProceso()}
            </div>
            {<Footer/>}
        </>
    );

}