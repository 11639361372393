import {formatDate, formatDateTimeConGuion} from "../../../core/utiles";
import MenuAdministracion from "../../../component/menu/administrador/menu";
import Footer from "../../../component/footer/footer";
import React, {useEffect, useState} from "react";
import {callApiAdministrarVerNotificaciones, callApiAdministrarVerNotificacionFecha} from "../../../core/restUtils";

export default function AdministrarNotificacionesAdmin() {

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        buscarDatosNotificaciones();
    }, []);

    const buscarDatosNotificaciones = () => {

        // recuperamos el querystring
        const querystring = window.location.search
        console.log(querystring)

        // usando el querystring, creamos un objeto del tipo URLSearchParams
        const params = new URLSearchParams(querystring)
        console.log(params)


        if (params != null && params.get("fecha") != null) {
            getNotificacionesPorFecha(params.get("fecha"));
        } else {
            // Buscar semana anterior
            getNotificaciones();
        }

    }

    const getNotificaciones = async () => {
        try {
            setLoading(true);
            const result = await callApiAdministrarVerNotificaciones()
            setPosts(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const getNotificacionesPorFecha = async (fecha) => {
        try {
            setLoading(true);
            const result = await callApiAdministrarVerNotificacionFecha(fecha)
            setPosts(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const renderNotificaciones = () => {

        if (loading) return <div className="carga"><p><img key="carga" alt="carga" src="/public/loading.gif" height="100" width="100"/></p>
        </div>;
        if (error) return <div className="carga"><p>Error: {error.message}</p></div>;

        return posts.map((item, index) => {

            // Formatear a data
            var fechaFormateada;
            if (item.auditoriafechaEnvio != null) {
                fechaFormateada = formatDateTimeConGuion(item.auditoriafechaEnvio[2], item.auditoriafechaEnvio[1], item.auditoriafechaEnvio[0], item.auditoriafechaEnvio[3], item.auditoriafechaEnvio[4])
            }
            return (
                <>
                    <ul>
                        <div className="oposicion" key={index}>
                            <h2>UUID: {item.uuidEntidad}</h2>
                            <h3>Tipo envio: {item.tipoEnvio}</h3>
                            <h3>Fecha Envio: {fechaFormateada}</h3>
                            <h3>Prioridad: {item.prioridad}</h3>
                            <h3>Enviado: {item.enviado ? 'SI' : 'NO'}</h3>
                            <h3>Trazas: {item.trazas}</h3>
                            <p><strong>Texto:</strong> {item.texto}</p>
                            <h3>Creado por: {item.auditoriaCreadorPor}</h3>
                        </div>
                    </ul>
                </>
            )
        });
    };

    return (
        <>
            {<MenuAdministracion/>}
            <div className="content">
                <h1>Listado de Notificaciones</h1>
                {renderNotificaciones()}
            </div>
            {<Footer/>}
        </>
    );

}